(function ($) {

    "use strict";

    /**
     * Описание объекта
     */
    var cart = function () {
        return cart.init.apply(this, arguments);
    };

    /**
     * Расширение объекта
     */
    $.extend(cart, {
        closeSuccessTimer: undefined,
        /**
         * Настройки по умолчанию
         */
        options: {
            routes: {
                index: '/cart',
                add: '/cart/add',
                remove: '/cart/remove',
                inc: '/cart/inc',
                dec: '/cart/dec',
                quantity: '/cart/quantity',

                add_opt: '/cart_opt/add',
                remove_opt: '/cart_opt/remove',
                dec_opt: '/cart_opt/dec',
            },
        },
        /**
         * Инициализация
         * @param options
         */
        init: function (options) {
            this.options = $.extend(this.options, options);

            this.bind();

            return this;
        },
        /**
         * "Навешиваем" события
         */
        bind: function () {
            var me = this;

            $(document).on('click', '[data-to-cart]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.add($button.data('id'),$button.data('model'));
                return false;
            });

            $(document).on('click', '[data-cart-dec]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.dec($button.data('cart-dec'));
                return false;
            });

            $(document).on('click', '[data-cart-inc]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.inc($button.data('cart-inc'));
                return false;
            });

            $(document).on('click', '[data-cart-remove]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.remove($button.data('cart-remove'));
                return false;
            });

            $(document).on('click', '[data-to-cart-opt]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.addopt($button.data('id'),$button.data('model'));
                return false;
            });

            $(document).on('click', '[data-cart-opt-remove]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.removeopt($button.data('cart-opt-remove'));
                return false;
            });

            $(document).on('click', '[data-cart-opt-dec]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.decopt($button.data('id'),$button.data('model'));
                return false;
            });
            
            $(document).on('click', '[data-close-success]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.closeSuccess();
                return false;
            }); 

            $(document).on('keydown', '[data-cart-change-count]', function(e) {
                var n = false;
                if (e.keyCode >= 96 && e.keyCode <= 105) n = true;
                if (e.keyCode >= 48 && e.keyCode <= 57) n = true;
                if (n) return ;

                if (e.keyCode >= 37 && e.keyCode <= 40) return ; // aroows
                if (e.keyCode == 46 || e.keyCode == 8) return ; // backspace delete

                e.preventDefault();
            }); 

            $(document).on('keyup', '[data-cart-change-count]', function(e) {
                if (e.keyCode >= 37 && e.keyCode <= 40) return ; // aroows
                if (e.keyCode == 46 || e.keyCode == 8) return ; // backspace delete

                var n = false;
                if (e.keyCode >= 96 && e.keyCode <= 105) n = true;
                if (e.keyCode >= 48 && e.keyCode <= 57) n = true;
                if (!n) return e.preventDefault();

                var $area = $(this);
                Stack(function(){
                    me.quantity($area.data('cart-change-count'), $area.html());
                });
                return false;
            });
        },
        add: function (id,model) {
            this.request(this.options.routes.add, {
                id: id,
                model: model
            });
        },
        dec: function (position) {
            this.request(this.options.routes.dec, {
                position: position
            });
        },
        inc: function (position) {
            this.request(this.options.routes.inc, {
                position: position
            }, function(e){
                if (e.open_opt) open_opt(e.open_opt);
            });
        },
        remove: function (position) {
            this.request(this.options.routes.remove, {
                position: position
            });
        },
        quantity: function (position, quantity) {
            this.request(this.options.routes.quantity, {
                position: position,
                quantity: quantity
            }, function(e){
                if (e.open_opt) open_opt(e.open_opt);
            });
        },

        addopt: function (id,model) {
            this.request(this.options.routes.add_opt, {
                id: id,
                model: model
            });
        },
        decopt: function (id,model) {
            this.request(this.options.routes.dec_opt, {
                id: id,
                model: model
            });
        },
        removeopt: function (position) {
            this.request(this.options.routes.remove_opt, {
                position: position
            });
        },

        request: function (route, request, after) {
            var me = this;
            $.ajax({
                url: route,
                dataType: 'json',
                type: 'post',
                data: request,
                success: function (data) {
                    if (data.status && !data.is_opt) {
                        me.message(data.status);
                    }
                    if (data.status == "success") {
                        me.update();
                    }
                    if (after) after(data);
                }
            });
        },
        /**
         * @param status "success"|"error"
         * @param message
         * @param data
         */
        message: function (status) {
            var me = this;
            if (status == 'success' && !$('.cart-box').hasClass('visible')) {
                var $successWrapper = $('.cart-success-wrapper');
                if ($successWrapper.length) {
                    $successWrapper.addClass('show');

                    clearTimeout(me.closeSuccessTimer);
                    me.closeSuccessTimer = setTimeout(function() {
                        me.closeSuccess();
                    }, 6000);
                }
            }
        },
        closeSuccess: function() {
            $('.cart-success-wrapper').removeClass('show');
        },
        update: function () {
            var me = this;
            var $blocks = $('[data-cart-block]');
            if ($blocks.length > 0) {
                $blocks.addClass('loading');
                $.ajax({
                    success: function (page) {
                        var $page = $(page);
                        me.updateBlocks($page);
                    }
                });
            }
        },
        updateBlocks: function ($page) {
            $page.find('[data-cart-block]').each(function () {
                var $newBlock = $(this);
                var name = $newBlock.data('cart-block');
                var $block = $('[data-cart-block="' + name + '"]');
                $block.removeClass('loading');
                $block.html($newBlock.html());
            });
        },
    });

    /**
     * Инициализация функции объекта для jQuery
     */
    return $.cart = function (options) {
        return cart.init(options);
    };

})($);

//$(function(){
//	$('body').cart();
//});

$.cart();