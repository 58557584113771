
require('../scss/app.scss');

require('../fonts/LemonTuesday/css/LemonTuesday.css');
require('../fonts/Muller/css/Muller.css');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('jquery');
//require('jquery/dist/jquery.min.js');
require('inputmask/dist/jquery.inputmask.bundle.js');

require('slick-carousel/slick/slick.min.js');

require('./utils.js');
require('./js_validation.js');
require('./links.js');
require('./cart.js');
require('./main.js');

