window.Close = function() {
    var closeCartBox = !$('body .modal').hasClass('visible');

    $('body .modal').removeClass('visible');
    setTimeout(function(){
        $('body .modal').hide();
        if (!closeCartBox) return ;
        $('html').removeClass('overflow');
        $('.cart-box').css({ display: 'none' }).removeClass('visible');
        $('.cart-box-background').fadeOut(300);
    }, 360);
};

window.Stack = (function(){
	var actions = {};
	return function(act, timer){
		if (!act) return;
		var ID = act.toString();
		if(actions[ID]) clearInterval(actions[ID]['timeout']);
		actions[ID] = {'timer' : timer || 500 };
		actions[ID]['timeout'] = setInterval(function(){
			if (actions[ID]['timer'] > 0) {
				return actions[ID]['timer'] -= 50;
			}
			clearInterval(actions[ID]['timeout']);
			act();
		}, 50);
	}
}());

$(document).keyup(function(e) {
    if (e.keyCode == 27) Close();
});

$(document).click(function(e) {
    if (!e.target.dataset.click) return;
    window[e.target.dataset.click]();
});

$(function() {
    "use strict";

    var pagination_after = function(){};

    $(document).on('click','[data-pager-ajax-load]', function (e) {
        e.preventDefault();
        var $this = $(this);
        var key = $this.closest('[data-pagination-nav]').data('pagination-nav');
        if (key) {
            $.ajax({
                url: $this.attr('href'),
                success: function (page) {
                    var $page = $(page);
                    var navSelector = '[data-pagination-nav="' + key + '"]';
                    var dataSelector = '[data-pagination-data="' + key + '"]';

                    $(dataSelector).replaceWith($page.find(dataSelector));
                    $(navSelector).replaceWith($page.find(navSelector));

                    pagination_after();
                }
            });
        }
        return false;
    });

    /* ---------------------------------------------------------------------- */
    // Editor prevent link action
    if ( window.location !== window.parent.location ) {
        $('a').click(function(e){ e.preventDefault(); });
    }

    /* ---------------------------------------------------------------------- */
    // Cart-open
    $('.open.cart').click(function(){
        var w = Math.max(1110, $(window).width());

        // -container/2 + button + padding
        var offset = -1060/2 + 200 + 65;
        if (w >= 1920) offset = -1780/2 + 275 + 100;

        $('.cart-box').css({
            display: 'block',
            width : w/2 + offset
        }).addClass('visible');

        $('.svirel-cart').css({
            right : w/2 + offset
        });

        $('.cart-box-background').fadeIn(300);
        $('.cart-success-wrapper').removeClass('show');

        setTimeout(function(){
            $('html').addClass('overflow');
        }, 2);
    });
    $('.cart-box-background').click(function(e){
        if (e.target.className == 'cart-box-background') Close();
    });
    $('.close.cart').click(Close);

    /* ---------------------------------------------------------------------- */
    // Cart-opt-open
    $('.cart-modal .colors .item').click(function(){
        $('.cart-modal .counter-wrapper').hide();
        $('.cart-modal .colors .item').removeClass('current');
        $('.cart-modal .counter-wrapper[data-id="'+ $(this).addClass('current').data('id') +'"]').show();
    });
    $('.cart-modal .counter-wrapper:first').show();
    $('.cart-modal .colors .item:first').addClass('current');

    $('.cart-modal [data-phone]').inputmask("+7 (999) 999-99-99", {
        'completed': true
    });
    $('.cart-modal .send-request').click(function(){
        if (!$('.cart-modal [data-phone]').inputmask("isComplete")) {
            $('.cart-modal [data-phone]').addClass('error');
            setTimeout(function(){
                $('.cart-modal [data-phone]').removeClass('error');
            }, 600);
            return ;
        }

        $.post('/', {
            'phone' : $('.cart-modal [data-phone]').val(),
            'user' : $('.cart-modal [data-name]').val(),
            'address' : $('.cart-modal [data-address]').val(),
            'type' : 'opt'
        }, function(){
            location.reload();
        });
    });

    window.open_opt = function(id){
        $('body .modal').show();

        if ($('.cart-modal .colors .item[data-id="'+id+'"]').length) {
            $('.cart-modal .counter-wrapper').hide();
            $('.cart-modal .colors .item').removeClass('current');
            $('.cart-modal .counter-wrapper[data-id="'+id+'"]').show();
            $('.cart-modal .colors .item[data-id="'+id+'"]').addClass('current');
        }

        setTimeout(function(){ $('body .modal').addClass('visible'); }, 1);
    };

    $('body .modal .close').click(Close);
    $('.get-many').click(open_opt);

    /* ---------------------------------------------------------------------- */
    // News-slider
    $('.history-page .articles .selector a').click(function(){
        $('.articles .selector li').removeClass('current');
        $(this).parent().addClass('current');
        var l = - parseInt($(this).parent().data('n')) * 100 + '%';
        $('.sectors').css({ left : l });
    });

    /* ---------------------------------------------------------------------- */
    // Mainpage:
    (function(){
        if ($('.svirel-items').length == 0) return ;
        $('.menu').addClass('cart-fix');

        var w = $(window).width(), offset = 95 * 2 + 120;
        if (w >= 1920) offset = 50 + 100;
        $('.scroll-box').css({
            maxHeight: $(window).height() - offset
        });

        $('.item .to-cart').click(function(){
            $('.menu .cart').addClass('added');
            setTimeout(function(){
                $('.menu .cart').removeClass('added');
            }, 900);
        });

        $('a[href="/#open-opt"]').click(open_opt);
    }());

    // Svirel:
    (function(){
        if ($('.svirel-image').length == 0) return ;
        var d = Math.random() * 10, hover = false, scale = 1.5;

        window.addEventListener("mousemove", function(e){
            hover = (e.pageX > 400 && e.pageY > 100 && e.pageY < 600);
        });

        var t = 0
        var hover_out = true;
        var addition_progress = 0;

        setInterval(function(){
            var add = 0;
            if (addition_progress > 0) add = Math.sin((addition_progress--)/200 * Math.PI)/400;
            d += 0.0023 + add;

            t = 4.5 * Math.sin(7*d) * scale;

            if (hover) {
                if (hover_out) {
                    hover_out = false;
                    if (addition_progress) return;
                    addition_progress = 200;
                }
            } else {
                if (!hover_out) hover_out = true;
            }

            $('.background .svirel').css({
                transform: 'rotate('+(t)+'deg)'
            });
        }, 24);

    }());

    // History
    (function(){
        if ($('.history-page').length == 0) return ;
        $('.video-slider').slick({
        	prevArrow: $('.slick-arrow.slick-prev'),
        	nextArrow: $('.slick-arrow.slick-next'),
        });
        $('.videos a').click(function(e){
            e.preventDefault();
            var m = $('body .modal.video[data-id="'+$(this).data('id')+'"]');
            m.find('.box').css({ marginTop: ($(window).height() - 345)/2 + 'px' });
            m.show();
            setTimeout(function(){ m.addClass('visible'); }, 1);
        });
    }());

    // Article
    (function(){
        if ($('.article-page, .sitemap').length == 0) return ;
        var h = Math.max($('.article-page').height() || 0, $('.sitemap').height() || 0);

        if (h > 200) $('.bird-x').css({
            display : 'block',
            top: (100 + Math.random() * 50) + 'px'
        });

        if (h > 500) $('.bird-y').css({
            display : 'block',
            top: 'auto',
            bottom: (300 + Math.random() * 300) + 'px'
        });
    }());

    // Materials
    (function(){
        $('.materials-items .shadow-box').click(function(){
            var m = $('body .modal.methods[data-id="'+$(this).data('id')+'"]');
            m.show();
            setTimeout(function(){ m.addClass('visible'); }, 1);
        });
    }());

    // Sticky Menu
    (function(){
        var lastDelta = 0;
        var currentDelta = 0;
        var point = 0;
        var opened = false;

        setInterval(function(){
            if (point != window.pageYOffset) {
                currentDelta = window.pageYOffset - point;
                point = window.pageYOffset;
            }

            if (currentDelta == lastDelta) return;

            if (window.pageYOffset > 350 && !$('body').hasClass('menu-sticky')) {
                $('body').addClass('menu-sticky');
            }
            if (window.pageYOffset > 150 && !$('body').hasClass('menu-button-hide')) {
                $('body').addClass('menu-button-hide');
            }
            if (window.pageYOffset <= 150) {
                $('body').removeClass('menu-button-hide');
            }
            if (window.pageYOffset < 250) {
                $('body').removeClass('menu-sticky');
            }
            if (window.pageYOffset > 450 && !$('.menu').hasClass('show-it')) {
                $('.menu').addClass('show-it');
            }
            if (window.pageYOffset < 450) {
                $('.menu').removeClass('show-it');
            }

            lastDelta = currentDelta;

            // АААаааа!!!!
        }, 20);
    }());

    // Articles
    (function(){
        if ($('.articles').length == 0) return ;

        pagination_after = function(){
            $('html, body').animate({
                scrollTop: $('.articles').offset().top + 400
            }, 350);
        };
    }());

    if ($('.go-to-video').length) {
        $('.go-to-video').click(function(){
            var element = $('.videos.block');
            $('html, body').animate({
                scrollTop: element.offset().top
            }, element.offset().top * 0.5);
        });
    }

    /* ---------------------------------------------------------------------- */
    $('.cart-box [data-phone]').inputmask("+7 (999) 999-99-99", {
        'completed': true
    });
    
    let error = (e) => {
        e.addClass('error');
        setTimeout(function(){
            e.removeClass('error');
        }, 600);
    };

    $('.cart-box .make-order').click(function(){
        let success = true;

        if (!$('.cart-box [data-phone]').inputmask("isComplete")) {
            success = false;
            error($('.cart-box [data-phone]'))
        }
        
        let data = {};
        $('.final-send input').each(function(){
            if ($(this).val().length == 0) {
                success = false;
                error($(this));
            }
            data[$(this).attr('name')] = $(this).val();
            
        });
        
        if (!success) return ;
        data['type'] = 'retail';
        $.post('/', data, function(){
            location.reload();
        });
    });

    $('[data-address]').keyup(function(){
        var val = $(this).val();
        return ;
        Stack(function(){
            $.get('http://maps.googleapis.com/maps/api/geocode/json', {
                address: val,
                sensor: false,
                  language: 'ru',
                  components: 'country:ru|administrative_area:Moscow'
            }, function(ret){
                console.log(ret);
            }, "json");
        });
    });

    $('.team-wrapper').hover(function(){
        var e = $(this);
        e.addClass('animate');
        setTimeout(function(){ e.addClass('stop'); }, 9 * 1000);
    });

    var name = location.hash.replace('#', '');

    if (name == 'open-opt') {
        location.hash = '';
        open_opt();
    }

    var element = $('[data-name="' +name+ '"]');
    var speed = 2;
    if (element && element.length > 0) {
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: element.offset().top
            }, element.offset().top / speed);
        }, 1000);
    }

});
